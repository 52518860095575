<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
        :title="$t('page.other.title')"
        @edit="$emit('edit')"
    >
    </page-title>
    <b-field
        :label="getFieldLabel('activities')"
        :type="getFieldType('activities')"
        :message="getFieldMessage('activities')"
    >
      <b-input
          v-if="isFieldEditable('$.other.activities')"
          v-model="model.activities"
          :placeholder="$t('form.field.activities.message')"
          maxlength="1000"
          type="textarea"
          @blur="validateActivities"
          autocomplete="off"
      >
      </b-input>
      <span v-else>{{ model.activities }}</span>
    </b-field>
    <revision-message path="$.other.activities"></revision-message>
    <b-field :label="getFieldLabel('incorporationdate')">
      <div v-if="isFieldEditable('$.other.asap')">
        <b-radio
            v-model="model.asap"
            :native-value="true"
            name="incorporationdate"
        >
          {{ $t('form.field.incorporationdate.asap') }}
        </b-radio>
        <b-radio
            v-model="model.asap"
            :native-value="false"
            name="incorporationdate"
        >
          {{ $t('form.field.incorporationdate.specificDate') }}
        </b-radio>
      </div>
      <span v-else-if="model.asap">{{ $t('form.field.incorporationdate.asap') }}</span>
      <span v-else-if="!isFieldEditable('$.other.incorporationdate')">
        {{ formatDateWithSlashes(model.incorporationdate) }}
      </span>
    </b-field>
    <b-field
        v-if="!model.asap && isFieldEditable('$.other.incorporationdate')"
        :type="getFieldType('incorporationdate')"
        :message="getFieldMessage('incorporationdate')"
    >
      <b-datepicker
          v-model="model.incorporationdate"
          :date-formatter="formatDateWithSlashes"
          :min-date="minIncorporationDate"
          icon="calendar-day"
          trap-focus
      >
      </b-datepicker>
    </b-field>
    <revision-message path="$.other.incorporationdate"></revision-message>
    <div class="columns">
      <div class="column is-half">
        <b-field :label="$t('form.field.employees.label')">
          <b-select
              v-if="isFieldEditable('$.other.employees')"
              v-model="model.employees"
              expanded
          >
            <option
                v-for="choice in $t('form.field.employees.choices')"
                :value="choice.value"
                :key="choice.value"
            >
              {{ choice.label }}
            </option>
          </b-select>
          <span v-else>
            {{ $t('form.field.employees.choices').find(choice => choice.value === model.employees).label }}
          </span>
        </b-field>
        <revision-message path="$.other.employees"></revision-message>
      </div>
      <div class="column is-half">
        <b-field :label="getFieldLabel('fiscalyearend')">
          <b-select
              v-if="isFieldEditable('$.other.fiscalyearend')"
              v-model="model.fiscalyearend"
              expanded
          >
            <option
                v-for="choice in $t('form.field.fiscalyearend.choices')"
                :value="choice.value"
                :key="choice.value"
            >
              {{ choice.label }}
            </option>
          </b-select>
          <span v-else>
            {{ $t('form.field.fiscalyearend.choices').find(choice => choice.value === '' + model.fiscalyearend).label }}
          </span>
        </b-field>
        <revision-message path="$.other.fiscalyearend"></revision-message>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <b-field :label="getFieldLabel('accountant')">
          <b-input
              v-if="isFieldEditable('$.other.accountant')"
              v-model="model.accountant"
              autocomplete="off"
          >
          </b-input>
          <span v-else>{{ model.accountant }}</span>
        </b-field>
        <revision-message path="$.other.accountant"></revision-message>
      </div>
      <div class="column is-half">
        <b-field :label="getFieldLabel('financialinstitution')">
          <b-input
              v-if="isFieldEditable('$.other.financialinstitution')"
              v-model="model.financialinstitution"
              autocomplete="off"
          >
          </b-input>
          <span v-else>{{ model.financialinstitution }}</span>
        </b-field>
        <revision-message path="$.other.financialinstitution"></revision-message>
      </div>
    </div>
    <div v-if="isViewModeCreate">
      <div class="content">
        <p class="label">{{ $t('page.other.provide-documents') }}</p>
        <ul>
          <li>Fondateur : {{ this.storedModel.founder.firstname + ' ' + this.storedModel.founder.lastname }}</li>
          <li
              v-for="(director, index) in this.storedModel.director"
              :key="`director${index}`"
          >
            {{ $t('page.director.section') + ' ' + (index + 1) + ' : ' + director.firstname + ' ' + director.lastname }}
          </li>
          <li
              v-for="(shareholder, index) in individuals"
              :key="`shareholder${index}`"
          >
            {{ $t('page.shareholder.section') + ' ' + (index + 1) + ' : ' + shareholder.firstname + ' ' + shareholder.lastname }}
          </li>
        </ul>
      </div>
      <section>
        <b-field
            :type="getFieldType('attachments')"
            :message="getFieldMessage('attachments')"
        >
          <b-upload v-model="localFiles"
                    @input="updateFiles"
                    multiple
                    drag-drop>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon
                      icon="upload"
                      size="is-large">
                  </b-icon>
                </p>
                <p>{{ $t('form.field.attachments.label') }}</p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <div class="tags">
            <span v-for="(file, index) in localFiles"
                  :key="index"
                  class="tag is-primary is-medium">
                {{ file.name }}
                <button class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)">
                </button>
            </span>
        </div>
      </section>
    </div>
  </fieldset>
</template>

<script>
  import page from '@/mixins/page'
  import date from '@/mixins/date'
  import { mapMutations, mapState } from 'vuex'
  import cloneDeep from 'lodash-es/cloneDeep'

  const MAX_SIZE = 35 * 1024 * 1024

  export default {
    name: 'PageOther',
    computed: {
      ...mapState('form', {
        files: 'files'
      }),
      individuals () {
        return this.storedModel.shareholder.filter(s => s.individual)
      }
    },
    mixins: [
      page('other'),
      date
    ],
    data () {
      const minIncorporationDate = this.addWeekdays(this.$moment(), 3).toDate()

      return {
        localFiles: [],
        minIncorporationDate
      }
    },
    mounted () {
      if (!this.model.activities && this.storedModel.companyName.activities) {
        this.model.activities = this.storedModel.companyName.activities
      }
      this.localFiles = cloneDeep(this.files)
    },
    methods: {
      ...mapMutations('form', [
        'SET_FILES'
      ]),
      deleteDropFile (index) {
        this.localFiles.splice(index, 1)
        this.updateFiles()
      },
      updateFiles () {
        this.SET_FILES(this.localFiles)
      },
      validate () {
        this.clearErrors()

        this.validateActivities()
        this.validateIncorporationDate()
        if (this.isViewModeCreate) {
          this.validateAttachments()
        }
      },
      validateActivities () {
        this.validateNotEmpty('activities', this.model.activities)
      },
      validateAttachments () {
        let totalSize = 0
        let extensionValid = true
        this.localFiles.forEach((file) => {
          totalSize += file.size
          let extension = file.name?.split('.')?.pop()
          if (!['png', 'pdf', 'jpeg', 'jpg'].includes(extension?.toLowerCase())) {
            extensionValid = false
          }
        })

        if (this.localFiles?.length < 1 || totalSize > MAX_SIZE || !extensionValid)
          this.addError('attachments')
        else
          this.removeError('attachments')
      },
      validateIncorporationDate () {
        if (!this.model.asap && !this.model.incorporationdate)
          this.addError('incorporationdate')
        else
          this.removeError('incorporationdate')
      }
    }
  }
</script>
